.menu{
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 100px;
    left: 0;
    opacity: 0;
    z-index: -1; 
    transition: all 0.5s;
}

.menu.active{
    opacity: 1;
    z-index: 10; 
}

.menuContent{
    width: 100%;
    height: 100%;
    background-color: #0f0e10;
    display: flex;
}

ul{
    padding: 0px 15px;
    margin: 0;
}

ul li{
    padding: 15px 0px;
    list-style-type: none;
}

ul li a{
    font-family: "Noto Sans JP", sans-serif;
    color: white;
    text-decoration: none;
    text-transform: uppercase;
}
