.wrapper{
    display: flex;
    width: 100%;
    justify-content: space-around;
    color: white;
    margin: 40px;
    @media screen and (max-width: 800px) {
      margin: 40px 0px;
      }

      @media screen and (max-width: 1230px) {
        flex-direction: column;
        }
  }
  
.tittle{
    width: 70%;
    font-size: 48px;
    font-weight: bolder;
    display: flex;
    margin-bottom: 40px;
    @media screen and (max-width: 1230px) {
      justify-content: center;
      width: 100%;
    }
    @media screen and (max-width: 850px) {
        font-size: 42px;
      }
      @media screen and (max-width: 405px) {
        font-size: 24px;
      }
}

.words{
  width: 60%;    
  @media screen and (max-width: 1230px) {
    width: 100%;

    }
} 

.logo{
  display: flex;
  width: auto;
  @media screen and (max-width: 1230px) {
    justify-content: center;

    }
}

.logo img{
  width: 100%;
  height: 70%;
  display: flex;
  align-items: center;
  transition: 0.5s;
  &:hover {
    transform: scale(1.08);
  }
    @media screen and (max-width: 1230px) {
    width: 50%;
    height: auto;

    }
    @media screen and (max-width: 555px) {
      width: 90%;
      }
}
