.wrapper{
    height: 100px;
    display: flex;
    width: 100%;
    border-bottom: 1px solid white;
  }
  

.inner{
  color: white;
  font-size: 16px;
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 10px;
}

.logo{
    display: flex;
    gap: 10px;
    align-items: center;
}

.headerItems{
    display: flex;
    width: auto;
    gap: 35px;
    justify-content: space-between;
    @media screen and (max-width: 585px) {
        display: none;
        }
}

a{
    color: white;
    text-decoration: none;
    transition: 0.5s all;
    &:hover{
        color: rgb(177, 177, 177);
    }
}

.button{
    @media screen and (max-width: 585px) {
        display: none;
        }
}
.mobileMenu{
    display: flex;
    flex-direction: row; 
    align-items: center; 
    justify-content: flex-end;
     
}


.burgerButton{
    display: none;
    cursor: pointer;
    width: 30px;
    height: 15px;
    margin-left: 20px;
    position: relative;
    background-color: rgb(0, 0, 0);
    @media screen and (max-width: 585px) {
        display: flex;
        }
}


.burgerButton::before{
    content: '';
    position: absolute;
    top: 0;
    width: 30px;
    background-color: white;
    height: 1.5px;
}

.burgerButton span{
    content: '';
    position: absolute;
    top: 6.5px;
    width: 30px;
    background-color: white;
    height: 1.5px; 
}

.burgerButton::after{
    content: '';
    position: absolute;
    bottom: 0;
    width: 30px;
    background-color: white;
    height: 1px;
}