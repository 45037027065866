
.imagesContainer {
    width: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
}

.image {
    width: 30%;
    transition: 0.5s;
    @media screen and (max-width: 850px) {
        width: 40%;
      }
      @media screen and (max-width: 850px) {
        width: 90%;
      }
}

.image:hover {
    transform: scale(1.05);
}