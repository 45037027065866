.wrapper{
    display: flex;
    flex-direction: column;
    width: 100%;
    color: white;
    align-items: center;
    justify-content: center;
    height: 600px;
    text-align: center;
    margin: 0 20px;
    @media screen and (max-width: 800px) {
       margin: 0 5px;
      }
  }
  
.tittle{
    width: 60%;
    font-size: 48px;
    font-weight: bolder;
    margin-bottom: 10px;
    @media screen and (max-width: 850px) {
        font-size: 42px;
        width: 100%;
      }
      @media screen and (max-width: 405px) {
        font-size: 24px;
        width: 100%;
      }
}

b{
    color:#8A54FD;
}
.subTittle{
    width: 30%;
    margin-bottom: 10px;
    @media screen and (max-width: 992px) {
        font-size: 12px;
        width: 100%;
      }
}

.buttons{
    display: flex;
    gap: 50px;
    padding: 20px;
    @media screen and (max-width: 405px) {
        gap: 20px;
      }
}

.learn {
    cursor: pointer;
    background-color: #ffffff; 
    color: #000000; 
    border: black 1px solid;
    padding: 10px 40px;
    transition: 0.3s;
    &:hover{
        background-color: black;
        color: white;
        border: rgb(255, 255, 255) 1px solid;
    }


}

.submit {
    cursor: pointer;
    background-color: #000000; 
    color: #fff; 
    padding: 10px 40px;
    border: white 1px solid;
    transition: 0.3s;
    &:hover{
        background-color: rgb(255, 255, 255);
        color: rgb(0, 0, 0);
        border: rgb(0, 0, 0) 1px solid;
    }
}