.ShowStyleOff{
    display: flex;
    opacity: 0;
    width: 100%;
    transform: translateY(70px);
    margin: 0 auto;

}
.ShowStyleOn{
    display: flex;
    width: 100%;
    opacity: 1;
    transition: all 1s;
    transform: translateY(0%);

}

